@import '../../styles/index';

.base {
  margin: 0;
  color: $color-surrogate--60;
  font-size: $font-size-18;
  font-weight: 500;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color $duration-default;

  &.white {
    color: $color-white--100;

    &:hover {
      color: $color-white--100;
    }
  }

  &.dark-gray {
    color: $color-black--40;

    &:hover {
      color: $color-surrogate--100;
    }
  }

  &.black {
    color: $color-black--100;

    &:hover {
      color: $color-black--100;
    }
  }

  &:hover {
    color: $color-surrogate--100;
  }

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.icon {
  margin-right: 0.5rem;
}

.navItem {
  .label {
    display: none;
    font-size: 1rem;
    line-height: 2;
    font-weight: $font-weight-regular;

    @include media-from(sm) {
      display: inline;
    }
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0;

    @include media-from(sm) {
      margin-right: 0.5rem;
    }
  }
}
