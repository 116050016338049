@import '../../styles/index';

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 550px;
  margin: 0 auto;
}

.headline {
  @include media-from(xs) {
    margin-bottom: 3.25rem;
  }

  & h2 {
    font-size: 2.25rem;

    @include media-from(xs) {
      font-size: 2.75rem;
    }
  }

  > div > div {
    color: $color-black--100;
    line-height: 2rem;
    font-size: 1.125rem;
    margin-top: 1rem;

    & hr:last-child {
      margin-bottom: 0;
      display: none;
    }
  }
}

.hr {
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  margin: 1rem 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #d9dadb;

  @include media-from(xs) {
    margin: 2rem 0;
  }
}

.link {
  display: block;
}

.links {
  width: 100%;
}
