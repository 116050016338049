@import '../../../styles/index';

.share-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;

  @include media-from(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.headline {
  color: $color-black--100;
  font-size: $font-size-22;
  letter-spacing: 0;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  margin: 0 0 1.5rem 0;
  font-weight: $font-weight-medium;

  @include media-from(sm) {
    font-size: $font-size-24;
    margin: 0 0 2rem 0;
  }
}
