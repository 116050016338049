@import '../../styles/index';

.base {
  align-items: center;
  border-bottom: 1px solid $color-surrogate--10;
  display: flex;
  margin: 0;
  padding: 1rem 0;

  @include media-from(lg) {
    padding: 1.5rem 0;
  }
}

.prefix-icon-wrapper {
  display: inline-flex;
  flex-shrink: 0;
  width: 2.75rem;
}

.prefix-icon {
  color: $color-surrogate--100;
  height: 1.75rem;
  width: 1.75rem;
}

.text-wrapper {
  color: $color-black--100;
  flex-shrink: 1;
  max-width: 55rem;
  min-width: 0;
  width: 100%;
}

.title {
  font-weight: $font-weight-medium;

  @include media-from(lg) {
    font-size: $font-size-18;
  }
}

.subtitle {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-from(lg) {
    font-size: $font-size-16;
  }
}

.suffix-icon-wrapper {
  display: none;
  flex-shrink: 0;
  justify-content: flex-end;
  width: 2.5rem;

  &-persistent {
    display: inline-flex;
  }

  @include media-from(lg) {
    display: inline-flex;
  }
}

.suffix-icon {
  color: $color-surrogate--100;
}
