@import '../../styles/index';

.stage-box {
  margin-bottom: 0 !important;
  @include media-from(xs) {
    margin-bottom: 5rem;
  }
  @include media-from(lg) {
    margin-bottom: 0;
  }
}

.benefits-headline,
.related-jobs-headline {
  margin-bottom: 2.5rem !important;
}

@include media-from(sm) {
  .benefits-headline,
  .related-jobs-headline {
    margin-bottom: 4rem !important;
  }
}

.apply-headline {
  max-width: 900px;
  padding: 0 1rem;

  @include media-from(sm) {
    padding: 0 2rem;
  }

  div {
    font-size: $font-size-16;

    @include media-from(xs) {
      font-size: $font-size-22;
    }
  }
}

.interest-section {
  padding: 3rem 0 !important;

  @include media-from(sm) {
    padding: 5rem 0 !important;
  }
}

.section-spacer {
  margin-top: 4rem !important;
  padding-bottom: 2rem;

  @include media-from(sm) {
    margin-top: 10rem !important;
  }
}

.job-description-container {
  display: flex;
  flex-direction: column;
  @include media-from(md) {
    flex-direction: row;
    justify-content: space-between;
  }

  :global(.RichText-module-base) h1,
  :global(.RichText-module-base) h2,
  :global(.RichText-module-base) h3,
  :global(.RichText-module-base) h4,
  :global(.RichText-module-base) h5,
  :global(.RichText-module-base) h6 {
    display: grid;
    grid-template-columns: auto;
    justify-content: start;
  }

  :global(.RichText-module-base) h1 > b,
  :global(.RichText-module-base) h2 > b,
  :global(.RichText-module-base) h3 > b,
  :global(.RichText-module-base) h4 > b,
  :global(.RichText-module-base) h5 > b,
  :global(.RichText-module-base) h6 > b {
    background: $color-violet--100
      linear-gradient(320deg, #{$color-red--100} 0%, #{$color-violet--100} 100%);
    background-clip: text;
    -webkit-background-clip: text;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -webkit-text-fill-color: transparent;
  }
}

.job-description-item {
  padding: 0;
  width: 100%;
  &:first-child {
    margin-bottom: 2.5rem;
  }

  h2,
  h2 > b {
    background: unset;
    background-clip: unset;
    -webkit-background-clip: unset;
    box-decoration-break: unset;
    // Chrome is the new internet explorer
    -webkit-box-decoration-break: unset;
    color: $color-black--100;
    -webkit-text-fill-color: unset;
  }

  @include media-from(md) {
    padding: 0;
    width: calc(50% - 0.75rem);
    &:first-child {
      margin-bottom: 0;
    }
  }
}

.additional-info {
  border: 2.5px solid $color-turquoise--100;
  border-radius: 6px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;

  @include media-from(md) {
    max-width: 52.75rem;
    padding: 2.5rem 3rem;
  }
}

.introduction {
  width: 100%;

  @include media-from(sm) {
    max-width: 75%;
  }
}
