@import '../../../styles/index';

.base {
  display: block;
  cursor: pointer;
  padding-bottom: 0.625rem;

  &:after {
    content: '';
    border-bottom: 1px solid $color-surrogate--20;
    display: block;
    margin: 0.625rem 0 0 0.625rem;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  .text {
    margin-left: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-18;
    color: $color-black--100;
    font-weight: $font-weight-medium;
  }
}

.inner-wrapper {
  padding: 0.625rem;
  border-radius: $border-radius-small;
  display: flex;
  &:hover,
  &.focus {
    background-color: $color-surrogate--10;
  }

  &.active {
    .text {
      color: $color-surrogate--100;
    }
  }
}
