@import '../../styles/index';

.base {
  margin: 0;
  max-width: 100%;
}

.collapse-direction-bottom {
  display: flex;
  flex-direction: column-reverse;
}

.button {
  cursor: pointer;
  color: $color-surrogate--100;
  line-height: 1.25;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: left;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.container {
  opacity: 0;
  max-height: 0;
  pointer-events: none;
  transition: max-height $duration-default;

  &.is-open {
    transition: opacity 2s;
    max-height: 100%;
    opacity: 1;
    pointer-events: all;
  }
}

.icon {
  transition: all $duration-default;
  margin-right: 0.5rem;

  &.is-open {
    transform: rotate(-180deg);
  }
}

.buttonReverse {
  flex-direction: row-reverse;

  .icon {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}
