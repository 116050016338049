@import '../../styles/index';

.base {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: $color-black--100;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-14;
  margin: 0;
  outline: none;
  padding: 1rem 0;

  & + & {
    border-top: 1px solid $color-surrogate--10;
  }

  @include media-from(lg) {
    border: 1px solid $color-surrogate--20;
    border-radius: 100px;
    font-size: $font-size-16;
    padding: 0.75rem 1.25rem;

    &:hover {
      background-color: $color-surrogate--03;
      border-color: $color-surrogate--100;
    }

    &:active {
      background-color: $color-white--100;
      border-color: $color-surrogate--100;
      box-shadow: 0 0 0 4px rgba($color-surrogate--100, 0.12);
    }

    & + &:not(:hover):not(:active) {
      border-top-color: $color-surrogate--20;
    }
  }
}
