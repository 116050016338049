@import '../../styles/index';

.base {
  align-items: center;
  border: 1px solid $color-surrogate--20;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  flex: 0 1 calc(20% - 1rem);
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 1.5rem 0 1rem;

  &:hover {
    background-color: $color-surrogate--03;
    border-color: $color-surrogate--100;
  }

  &:active {
    background-color: $color-white--100;
    border-color: $color-surrogate--100;
    box-shadow: 0 0 0 4px rgba($color-surrogate--100, 0.12);
  }
}

.icon {
  color: $color-surrogate--100;
}

.text {
  color: $color-black--100;
  font-weight: $font-weight-medium;
  margin-top: 0.75rem;
}
