@import '../../styles/index';

.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fae6ee;
  padding: 0.75rem 1.25rem;
  border-radius: 25px;
  margin: 0 0.5rem 0 0;
  height: 2.5rem;

  &.with-gradient {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .title {
    color: $color-surrogate--100;
    font-size: $font-size-16;
    font-weight: $font-weight-medium;
    text-transform: capitalize;

    &.with-gradient {
      color: white;
    }
  }

  .icon {
    color: $color-surrogate--100;
    margin-left: 0.5rem;
    cursor: pointer;

    &.with-gradient {
      color: white;
    }
  }
}
