@import '../../styles/index';

.base {
  margin: 0;
}

.headline {
  font-weight: $font-weight-regular;
  font-size: $font-size-28;
  line-height: 1.3;
  text-align: center;
  color: $color-green--100;
  margin: 0 0 1.5rem 0 !important;

  @include media-from(sm) {
    margin-bottom: 2rem !important;
  }

  strong {
    font-weight: $font-weight-medium;
  }
}

.description {
  font-weight: $font-weight-light;
  font-size: $font-size-18;
  line-height: 1.6;
  text-align: center;
  margin-top: 0;
}
