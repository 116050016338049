@import '../../styles/index';

.wrapper {
  height: inherit;
}

.base {
  align-items: flex-start;
  background-color: $color-white--100;
  border-radius: $border-radius-default;
  border: 2px solid $color-surrogate--20;
  color: $color-black--60;
  cursor: pointer;
  display: flex;
  font-size: $font-size-18;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  padding: 0.90625rem 1rem;

  position: relative;
  transition: color $duration-default, border-color $duration-default,
    box-shadow $duration-default, background-color $duration-default;
  user-select: none;

  &.has-badge {
    border-radius: 0 0 $border-radius-default;

    @include media-from(sm) {
      border-radius: $border-radius-default;
      align-items: center;
    }
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  &:not(.has-badge) > *:not(:last-child) {
    flex-shrink: 0;
  }

  & a {
    color: #c7105c;
  }

  .checked & {
    border-color: $color-surrogate--100;
    box-shadow: 0 1px 3px 0 rgba(199, 16, 92, 0.12),
      0 8px 16px 0 rgba(199, 16, 92, 0.12);
    color: $color-surrogate--100;
  }

  .error & {
    border-color: $color-red--100;
    background-color: $color-red--05;
    box-shadow: 0 1px 3px 0 rgba(199, 16, 92, 0.12),
      0 8px 16px 0 rgba(199, 16, 92, 0.12);
    color: $color-red--100;
  }
}

.label {
  font-size: $font-size-16;
  color: $color-surrogate--60;
  display: block;
  transition: color $duration-default;

  @include media-from(xs) {
    font-size: $font-size-18;
  }

  &.small {
    width: calc(100% - 3rem);
    overflow-wrap: break-word;

    @include media-from(xs) {
      font-size: $font-size-16;
    }
  }

  .error & {
    color: $color-red--100;
  }
}

.badge-mobile {
  background-color: $color-black--20;
  border-radius: $border-radius-default $border-radius-default 0 0;
  border: 1px solid $color-black--20;
  color: $color-black--40;
  display: block;
  font-size: $font-size-16;
  margin: 0;
  padding: 0.125rem;
  text-align: center;
  width: 100%;
  transition: color $duration-default, background-color $duration-default,
    border-color $duration-default;

  @include media-from(sm) {
    display: none;
  }
}

.badge {
  background-color: $color-black--10;
  color: $color-black--40;
  font-size: 0.875rem;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  margin-left: auto;
  padding: 0.5rem 0.75rem;
  transition: color $duration-default, background-color $duration-default;
  white-space: nowrap;

  @include media-to(sm) {
    display: none;
  }
}

.checked {
  .badge,
  .badge-mobile {
    background-color: $color-surrogate--100;
    border-color: $color-surrogate--100;
    color: $color-white--100;
  }

  .label {
    color: $color-surrogate--100;
  }
}
