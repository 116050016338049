@import '../../styles/index';

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headline {
  @include media-from(xs) {
    margin-bottom: 2.25rem;
  }

  & h2 {
    font-size: 2.25rem;

    @include media-from(xs) {
      font-size: 2.75rem;
    }
  }

  > div {
    color: $color-black--100;
    line-height: 2rem;
    font-size: 1.125rem;
    margin-top: 1rem;
  }
}

.divider {
  margin: 1.5rem 0;

  @include media-from(xs) {
    margin: 2.5rem 0;
  }
}

.button {
  min-width: 11.8125rem;
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
}

.text {
  margin-bottom: 1rem;
  text-align: center;

  > span {
    color: $color-black--60;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0.25rem;

    @include media-from(xs) {
      text-align: left;
    }
  }
}

.register {
  margin: 0 auto;
}

.link {
  color: $color-surrogate--100;
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
  margin-top: 0.875rem;
  user-select: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.error-banner {
  margin-bottom: 2rem;
}
