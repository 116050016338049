@import '../../styles/index';

.base {
  background-color: $color-surrogate--05;
  margin: 0;
  overflow: hidden;

  @include media-from(md) {
    background-color: $color-white--100;
  }
}

.list-wrapper {
  position: relative;

  .scroll-button {
    position: absolute;
    top: 0;
    appearance: none;
    border: none;
    background: none;
    color: $color-surrogate--100;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
    bottom: 1px;
    padding-inline: 0.625rem;

    &:disabled {
      color: $color-surrogate--20;
      pointer-events: none;

      @include media-from(md) {
        display: none;
      }
    }

    &.next {
      right: 0;
      padding-left: 2.25rem;

      @include media-from(md) {
        background: linear-gradient(
          90deg,
          rgba($color-white--100, 0) 0%,
          rgba($color-white--100, 0.7) 44%,
          rgba($color-white--100, 1) 100%
        );
      }

      &:hover {
        transform: translateX(4px);
      }
    }

    &.prev {
      left: 0;
      padding-right: 2.25rem;

      @include media-from(md) {
        background: linear-gradient(
          270deg,
          rgba($color-white--100, 0) 0%,
          rgba($color-white--100, 0.7) 44%,
          rgba($color-white--100, 1) 100%
        );
      }

      &:hover {
        transform: translateX(-4px);
      }
    }
  }
}

.list {
  display: flex;
  flex-wrap: nowrap;
  height: 5.5625rem;
  margin-bottom: 1rem;
  margin-inline: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  border-bottom: 1.5px solid $color-surrogate--20;
  scrollbar-width: none;

  @include media-from(md) {
    height: 4.8125rem;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.trigger {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $color-surrogate--100;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  gap: 0.5rem;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding-inline: 1.75rem;
  position: relative;
  scroll-snap-align: center;
  width: 100%;
  transition: color 0.3s ease-in-out;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  &[data-state='active'] {
    color: $color-surrogate--100;
  }

  @include media-from(sm) {
    font-size: $font-size-20;
  }

  @include media-from(md) {
    color: $color-black--100;
    width: unset;
    padding-inline: 3rem;

    &[data-state='active']::after {
      background-color: $color-surrogate--100;
      bottom: 0;
      content: '';
      height: 0.25rem;
      position: absolute;
      width: 100%;
    }
  }

  .icon {
    @include media-to(md) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.content {
  background-color: $color-surrogate--05;
  padding: 1rem;

  @include media-from(sm) {
    padding: 7.25rem 1rem;
  }

  &-inner {
    margin-inline: auto;
    width: min(58rem, 100%);
  }
}
