@import '../../styles/index';

$mobile-search-header-height: 83px;
$desktop-search-header-height: 97px;

.base {
  border-top: 1px solid $color-surrogate--10;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding-bottom: 1rem;
  padding-top: $mobile-search-header-height;

  @include media-from(lg) {
    padding-bottom: 0;
    padding-top: $desktop-search-header-height;
  }
}

.scopes {
  align-items: center;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba($color-white--100, 0.8);
  display: flex;
  padding: 1.25rem;
  overflow-x: auto;

  &::after {
    content: '';
    display: block;
    height: 0.02px;
    padding-left: 1.25rem;
    width: 1.25rem;
  }

  @include media-from(lg) {
    justify-content: center;
    position: sticky;
    top: 0;

    &::after {
      content: unset;
      display: none;
    }
  }
}

.scope {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: $color-surrogate--40;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-14;
  margin: 0;
  outline: none;
  padding: 0;
  white-space: nowrap;

  &[data-active='true'] {
    color: $color-surrogate--100;

    & .scope-name::after {
      background-color: $color-surrogate--100;
      border-radius: $border-radius-large;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  & + & {
    margin-left: 2rem;
  }

  @include media-from(lg) {
    font-size: $font-size-16;
  }
}

.scope-name {
  position: relative;
}

.loader-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: calc(100vh - $mobile-search-header-height);

  @include media-from(lg) {
    height: calc(100vh - $desktop-search-header-height);
  }
}

.results-wrapper {
  margin: 0 1.25rem;

  @include media-from(lg) {
    margin: 0 auto;
    max-width: 920px;
    width: 100%;
  }
}

.result-text {
  font-weight: $font-weight-light;
  margin: 1.5rem 0 1rem;

  @include media-from(lg) {
    font-size: $font-size-22;
    margin: 4rem 0 1.5rem;
  }
}

.result-headline {
  margin-top: 1.5rem;

  @include media-from(lg) {
    margin-top: 7.5rem;
  }
}

.query-text {
  font-weight: $font-weight-medium;
}

.suggestions-wrapper {
  margin: 0 -1.25rem;
  padding: 1.5rem 1.25rem 0;

  .result-text + & {
    border-top: 1px solid $color-surrogate--10;
    margin-top: 2.5rem;
  }

  @include media-from(lg) {
    margin: 7.5rem 0 0;
    padding: 0;

    .result-text + & {
      border-top: none;
      margin-top: 4rem;
    }
  }
}

.suggestions-section {
  & + & {
    margin-top: 3rem;
  }
}

.suggestions-section-title {
  color: $color-surrogate--40;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  margin-bottom: 0.5rem;

  @include media-from(lg) {
    color: $color-black--100;
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
    margin-bottom: 1rem;
  }
}

.suggestions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-from(lg) {
    flex-direction: row;
    gap: 1rem;
  }
}
