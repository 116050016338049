@import '../../styles/index';

.default {
  align-items: center;
  background-color: $color-turquoise--100;
  border-radius: 50% 50% 0 50%;
  color: $color-white--100;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  height: $badge-xxs;
  justify-content: center;
  line-height: 1.25;
  padding: 1rem;
  text-align: center;
  width: $badge-xxs;

  p {
    margin-block-end: 0;
    margin-block-start: 0;
  }

  a {
    color: $color-white--100;
    text-decoration: underline;
  }

  %desktop-style {
    border-radius: 50% 50% 50% 0;
    height: $badge-sm;
    width: $badge-sm;
  }

  &.mobile-none {
    @extend %desktop-style;
  }

  @each $breakpoint in xs, sm, md, lg {
    &.mobile-#{$breakpoint} {
      @extend %desktop-style;
    }
  }

  &.no-tail {
    border-radius: 50%;
  }

  &.mobile-rectangle {
    @include media-to(xs) {
      border-radius: 0;
      height: auto;
      padding: 0.5rem 0.75rem;
      width: 100%;

      &,
      div {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        justify-content: center;
      }
    }
  }
}

.text-highlight {
  font-size: 1rem;
  font-weight: $font-weight-regular;

  @include media-from(sm) {
    font-size: 1.25rem;
  }

  strong {
    font-weight: $font-weight-bold;
  }
}

.text-default {
  font-size: 1rem;
  font-weight: $font-weight-light;

  @include media-from(sm) {
    font-size: 1.125rem;
  }

  strong {
    font-weight: $font-weight-medium;
  }
}

.text-special {
  font-size: 0.8125rem;
  font-weight: $font-weight-regular;

  @include media-from(sm) {
    font-size: 1rem;
  }

  strong {
    font-weight: $font-weight-bold;
  }
}

.text-medium {
  font-size: 0.8125rem;
  font-weight: $font-weight-regular;

  @include media-from(sm) {
    font-size: 1rem;
  }

  strong {
    font-weight: $font-weight-medium;
  }
}

.image {
  display: block;
}

.yellow {
  background-color: $color-yellow--100;
}

.green {
  background-color: $color-green--100;
}

.turquoise {
  background-color: $color-turquoise--100;
}

.blue {
  background-color: $color-blue--100;
}

.orange {
  background-color: $color-orange--100;
}

.purple {
  background-color: $color-violet--100;
}

.white {
  background-color: $color-white--100;

  div {
    padding-bottom: 0.5rem;

    @extend %text-linear-gradient;
  }
}
