@import '../../styles/index';

.base {
  &:not(:first-child) {
    hr:first-child {
      display: none;
    }
  }
}

.headline {
  color: $color-black--100;
  font-size: $font-size-16;
  letter-spacing: 0;
  line-height: 1.6;
  margin: 0;
  font-weight: $font-weight-medium;
  max-width: 85%;

  @include media-from(xsbetween) {
    max-width: 90%;
  }

  @include media-from(md) {
    font-size: $font-size-18;
  }
}

.headline-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: padding $duration-default;
}

.headline {
  line-height: 1.5;

  @include media-from(md) {
    line-height: 1.6;
  }
}

.content-container {
  opacity: 0;
  max-height: 0;
  transition: max-height $duration-default, opacity $duration-default;

  .open & {
    max-height: 100%;
    opacity: 1;
  }
}

.description {
  font-size: $font-size-16;
  line-height: 1.5;

  @include media-from(md) {
    font-size: $font-size-18;
    line-height: 1.6;
  }
}

.button {
  position: relative;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-right: 0.125rem;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: $color-surrogate--100;
    transition: transform $duration-default;
  }

  /* Vertical */
  &:before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    border-radius: 2px;
  }

  /* horizontal */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    border-radius: 2px;
  }

  .open & {
    &:before {
      transform: rotate(90deg);
    }
    &:after {
      transform: rotate(180deg);
    }
  }
}

.hr {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $color-surrogate--20;
  margin: 0;
  cursor: pointer;

  &:not(:first-child) {
    padding-top: 0.5rem;
    @include media-from(md) {
      padding-top: 0.375rem;
    }
  }
}

.default {
  .headline-container {
    padding: 1.25rem 0 0.75rem 0;

    @include media-from(md) {
      padding: 1.375rem 0 1rem 0;
    }
  }

  .content-container {
    padding-inline: 1rem;
    padding-bottom: 0.75rem;

    @include media-from(sm) {
      padding-bottom: 1.125rem;
    }
  }

  &:hover,
  &.open {
    background-color: $color-surrogate--03;

    .headline-container {
      padding-inline: 1rem;
    }
  }
}

.highlighted {
  background-color: $color-surrogate--03;
  border-radius: 0.5rem;

  .headline-container {
    padding: 1.25rem;

    @include media-from(md) {
      padding: 1.375rem 1.25rem;
    }
  }

  .content-container {
    border-top: 1px solid $color-surrogate--20;
    padding: 1rem;

    @include media-from(md) {
      padding: 1.5rem 1.25rem;
    }
  }

  .hr {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .headline {
    color: $color-surrogate--100;
  }
}
