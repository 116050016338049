@import '../../styles/index';

.base {
  max-height: 100%;
  height: 53px;
  color: $color-surrogate--100;
}

.invert {
  color: $color-white--100;
}
