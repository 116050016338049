@import '../../styles/index';

.base {
  width: 100%;
  margin-bottom: 2rem;
  user-select: none;
  margin-top: 2.5rem;

  &,
  > * {
    text-align: center;
  }
}

.label {
  color: $color-black--40;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  margin: 0 0 0.4rem;

  @include media-from(sm) {
    margin: 0 0 1rem;
  }

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}

.headline {
  color: $color-black--100;
  font-size: $font-size-28 !important;
  font-weight: $font-weight-regular;
  margin: 0;

  > strong,
  > span > strong {
    font-weight: $font-weight-medium;
  }

  > span {
    color: $color-green--100;
  }

  @include media-to(xs) {
    line-height: 1.3;
  }

  @include media-from(sm) {
    font-size: $font-size-30;
  }
}

.sub .headline {
  font-size: $font-size-22;
}

.sub {
  margin: 1rem 0;
}

.error {
  .headline {
    color: $color-red--100;
  }
}
