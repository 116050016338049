@import '../../styles/index';

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headline {
  @include media-from(xs) {
    margin-bottom: 2.25rem;
  }

  & h2 {
    font-size: 2.25rem;

    @include media-from(xs) {
      font-size: 2.75rem;
    }
  }

  > div {
    color: $color-black--100;
    line-height: 2rem;
    font-size: 1.125rem;
    margin-top: 1rem;
  }
}

.button {
  min-width: 11.8125rem;
  margin-top: 1.725rem;
  margin-bottom: 0.25rem;
}

.formfield {
  padding: 0.75rem 0;
  margin: 0 auto;
  min-width: calc(100vw - 3rem);
  width: 100%;

  @include media-from(xs) {
    min-width: unset;
    width: 24.75rem;
  }
}
