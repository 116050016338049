@import '../../../styles/index';

.base {
  .filter {
    display: flex;
    width: 100%;

    & > div {
      flex: 1 1 0;
      min-width: 0;

      @include media-from(sm) {
        max-width: 25%;
      }
    }

    & > div:not(:last-child) {
      margin-right: 1rem;

      @include media-to(sm) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }

  @include media-to(sm) {
    .filter > div:not(:first-child) {
      display: none;
    }
  }
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1.5rem;

  .icon {
    color: $color-surrogate--100;
    margin-right: 0.5rem;
  }

  .text {
    color: $color-surrogate--100;
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
  }

  @include media-from(sm) {
    display: none;
  }
}
