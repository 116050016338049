@import '../../styles/index';

body.body {
  background-color: $color-white--100;

  strong {
    font-weight: 500;
  }

  :global(.Box-module-shadow) {
    box-shadow: $shadow-configurator-light;
  }

  :global(.BoxWithArrow-module-base) {
    border: none;
    box-shadow: $shadow-configurator-light;
  }

  :global(.CheckBoxCard-module-active > .CheckBoxCard-module-label) {
    color: $color-surrogate--100;
  }

  :global(.CheckBoxCard-module-label) {
    color: $color-surrogate--10;
  }
}

.base {
  background-color: $color-white--100;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
