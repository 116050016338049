@import '../../styles/index';

$header-height-mobile: 4.375rem;
$header-height-desktop: 5rem;

.base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(2rem + #{$header-height-mobile}) 0 4rem;
  position: relative;

  @include media-from(sm) {
    padding: calc(4rem + #{$header-height-desktop}) 0 6.875rem;
  }
}

.container {
  max-width: 40rem; // 640px
  width: 100%;

  @include media-from(xs) {
    :global(.FormField-module-half) {
      width: calc(50% - 0.375rem) !important;
    }
  }
}

.termsbox-subtext {
  color: $color-black--60;
  font-size: $font-size-16;
  line-height: 1.5;
}

.termsbox-headline {
  color: $color-black--100;
  font-size: $font-size-20;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 0.75rem;
}

.radios {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  :global(
      .RadioInput-module-default
        input.RadioInput-module-inactive
        ~ .RadioInput-module-label-container
        .RadioInput-module-label
    ) {
    color: $color-black--100;
  }

  label {
    font-size: $font-size-18;
    margin-left: 0.75rem;
    margin-top: 1.5rem;
  }

  @include media-from(xs) {
    flex-direction: row;
    flex-wrap: wrap;

    label {
      flex-basis: calc(50% - 0.75rem);
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: 0;
    }
  }
}

.modal-headline {
  color: $color-black--100;
  font-size: $font-size-24;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-28;
  }
}

.box {
  background-color: rgba($color-white--100, 0.72);
  border: 1.5px solid $color-black--20;
  border-radius: 6px;
  padding: 1.25rem;
  position: relative;

  @include media-from(xs) {
    padding: 1.5rem;
  }
}

.box-personal-data {
  display: flex;
  flex-direction: column;
}

.box-content-personal-data {
  margin-bottom: 1rem;

  @include media-from(xs) {
    margin-bottom: 2.5rem;
  }
}

.box-content-location-data {
  margin-bottom: 1rem;

  @include media-from(xs) {
    margin-bottom: 0;
    position: absolute;
    right: 50%;
    top: 4rem;
    transform: translateX(100%);
  }
}

.box-application-questions-hr {
  background-color: $color-black--20;
  border: none;
  height: 1px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}

.box-edit-icon {
  color: $color-surrogate--100;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;

  @include media-from(xs) {
    right: 1.6rem;
    top: 1.6rem;
  }
}

.box-headline {
  margin-bottom: 1rem;
  max-width: calc(100% - 2rem);

  h4 {
    font-weight: 500 !important;

    @include media-to(sm) {
      font-size: $font-size-20 !important;
    }
  }
}

.box-headline-large {
  margin-bottom: 1.5rem;
}

.box-block-title {
  color: $color-black--60;
  font-size: $font-size-18;
  line-height: 1.3;
}

.box-block-text {
  color: $color-black--100;
  font-size: $font-size-18;
  line-height: 1.3;
  margin: 0.25rem 0 0;

  b {
    font-weight: 500;
  }
}

.links {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include media-from(md) {
    bottom: 3rem;
    display: flex;
    flex-direction: row;
    left: unset;
    position: absolute;
    right: 3rem;
    transform: none;
  }

  a {
    color: $color-black--60;
    font-size: $font-size-16;
    line-height: 1.3;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
      margin-right: 1.5rem;

      @include media-from(xs) {
        margin-right: 2.5rem;
      }

      @include media-from(md) {
        margin-bottom: 0;
      }
    }
  }
}

.step-headline {
  color: $color-black--100;
  font-size: $font-size-22;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0 0 0.5rem 0;
}

.modal-text {
  margin-bottom: 2rem;

  @include media-from(sm) {
    li {
      font-size: $font-size-18;
      margin-bottom: 0.5rem;
      padding-left: 1.75rem !important;

      &::before {
        height: 1.125rem !important;
        margin-left: -1.75rem !important;
        margin-top: 0.25rem !important;
        width: 1.125rem !important;
      }
    }
  }

  p {
    font-size: $font-size-18;
    margin-bottom: 1rem;
  }
}

.upload-field {
  margin-bottom: 2rem;

  @include media-from(sm) {
    margin-bottom: 3rem;
  }
}

.headline h1 {
  @include media-to(sm) {
    font-size: $font-size-28 !important;
  }
}
