@import '../../../styles/index';

.item {
  display: flex;
  padding: 0.625rem;
  border-radius: $border-radius-small;
  cursor: pointer;

  &:hover,
  &.focus {
    background-color: $color-surrogate--10;
  }

  .text {
    margin-left: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-18;
    color: $color-surrogate--60;
  }

  &.active {
    color: $color-surrogate--100;
  }

  &.indented {
    padding-left: 1.625rem;
  }
}
