@import '../../styles/index';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include media-from(sm) {
    flex-direction: row;
  }
}

.image-container {
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 2rem;
  flex-shrink: 0;

  @include media-from(sm) {
    margin: 0 2rem 0 0;
  }

  & + .box {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: -10px;
      transform: translateX(-50%) rotate(135deg);
      height: 16px;
      width: 16px;
      background-color: $color-white--100;
      border-left: 1.5px solid $color-black--20;
      border-bottom: 1.5px solid $color-black--20;
      border-radius: $border-radius-default 0 0 0;

      @include media-from(sm) {
        left: -10px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        border-radius: 0 0 0 $border-radius-default;
      }
    }
  }
}

.image {
  height: 100%;
  width: 100%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  img {
    border-radius: 50%;
  }
}

.box {
  border-radius: $border-radius-default;
  background-color: $color-white--100;
  box-shadow: $shadow-configurator-light;
  position: relative;
  padding: 1.5rem;
  font-size: $font-size-18;
  line-height: 1.55;
  color: $color-black--100;

  strong {
    font-weight: $font-weight-medium;
  }

  @include media-from(sm) {
    padding: 2rem;
  }
}
