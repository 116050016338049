@import '../../styles/index';

.base {
  border-top: 1px solid $color-surrogate--10;
  margin: 0 0 1rem;

  @include media-from(lg) {
    margin-bottom: 3rem;
  }
}
