@import '../../uikit/styles/index';

.additional-margin-top {
  margin-top: 1rem;

  &-large {
    margin-top: 2rem;
  }

  &-price-bar {
    @include media-to(sm) {
      margin-top: 90px;
    }
  }
}

.additional-margin-bottom {
  margin-bottom: 1rem;
}

.padding-on-mobile {
  @include media-to(md) {
    padding-top: 2rem;
  }
}

.margin-bottom {
  margin-bottom: 1rem;

  &-medium {
    margin-bottom: 1.5rem;
  }
}

.sub-headline-margin {
  margin-bottom: 1rem;
  margin-top: 2rem;

  &-medium {
    margin-bottom: 1.5rem;
  }
}

.large-margin-bottom {
  margin-bottom: 2rem;

  @include media-from(sm) {
    margin-bottom: 3rem;
  }
}

.large-margin-bottom-only-mobile {
  @include media-to(sm) {
    margin-bottom: 2rem;
  }
}

.form-element {
  @include media-from(sm) {
    margin-top: -1rem;
  }
}

.time-slot-group {
  > label {
    @include media-from(sm) {
      margin: 1rem 1rem 0;
    }
  }
}

.box-with-arrow-text {
  color: $color-black--60;
}

.spacing {
  @include space-between-elements(2rem);
}

.image {
  margin: 0 auto;
  max-width: 120px;

  & > picture > img {
    left: unset !important;
    right: 1rem;
  }
}

.footer-text {
  margin-top: -0.5rem;
  text-align: center;
  user-select: none;
}

.info-text {
  color: $color-black--100;
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.plant {
  color: $color-green--100;
  margin-bottom: -1.5rem;
}

.lightning {
  color: $color-surrogate--100;
  margin-bottom: -1.5rem;
}

.craftsman {
  color: $color-turquoise--100;
  margin-bottom: -1.5rem;
}

.continue-text {
  color: $color-white--100;
  font-size: $font-size-14;
  line-height: 1.4;

  &-large {
    font-size: $font-size-16;
    line-height: 1.25;
  }
}

.order-text {
  color: #a6a7ab;
  font-size: $font-size-14;
  line-height: 1.4;

  &-large {
    color: #a6a7ab;
    font-size: $font-size-16;
    line-height: 1.25;
  }
}

.order-headline {
  color: $color-black--100;
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.box {
  background-color: $color-white--100;
  border-radius: $border-radius-default;
  box-shadow: $shadow-configurator-light;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  padding: 1.75rem 1rem;
  position: relative;
  width: 100%;

  .edit-button-desktop {
    position: absolute;
    right: -23px;
    top: 23px;

    @include media-to(sm) {
      display: none;
    }
  }

  .edit-button-mobile {
    position: absolute;
    right: 16px;
    top: 20px;

    @include media-from(sm) {
      display: none;
    }
  }

  @include media-from(sm) {
    padding: 1.75rem 2rem;
  }
}

.wallbe {
  text-align: center;

  @include media-from(sm) {
    text-align: left;
  }
}

.mobile {
  @include media-from(sm) {
    display: none;
  }
}

.order-summary {
  &-headline {
    font-size: $font-size-20;
    font-weight: $font-weight-medium;
  }

  &-sub-headline {
    font-size: $font-size-18;
    font-weight: $font-weight-medium;
  }

  &-payment-amount {
    display: flex;
    justify-content: space-between;

    &-highlight {
      color: $color-surrogate--100;
      font-size: $font-size-18;
      font-weight: $font-weight-medium;
    }

    &-highlight-amount {
      color: $color-surrogate--100;
      font-size: $font-size-22;
      font-weight: $font-weight-medium;
    }

    &-second {
      color: #94959a;
      font-size: $font-size-16;
    }
  }

  hr {
    border: 1px solid#f0f0f0;
    margin: 1.5rem 0 1.5rem;
    width: calc(100%);

    @include media-from(sm) {
      margin: 1.5rem 0;
      width: calc(100% + 2rem);
    }
  }

  ul {
    color: #a6a7ab;
    list-style-type: none;
    margin: 1rem 0;
    padding: 0;
    position: relative;

    li {
      margin-bottom: 0.5rem;
      padding-left: 1rem;

      @include media-from(sm) {
        margin-bottom: 0.5rem;
        padding-left: 1.5rem;
      }

      p {
        margin-bottom: 0;
        margin-top: 0.5rem;
      }

      &::before {
        // to encode use https://yoksel.github.io/url-encoder/
        // background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 34 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd' fill='%23c7105c'%3E%3Cg transform='translate(-540 -1038)' fill-rule='nonzero'%3E%3Cg transform='translate(540 1026)'%3E%3Cpolygon points='29.866 12 12.22 29.37 3.5566 22.772 0 27.32 10.7 35.459 12.721 37 34 16.054'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center;
        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='17px' viewBox='0 0 16 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 56.2 (81672) - https://sketch.com --%3E%3Ctitle%3EICONS/check%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Landingpage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='enercity_emobilität-überblick-wallbe_pro' transform='translate(-835.000000, -2003.000000)' fill='%23009AA3'%3E%3Cg id='Group-12' transform='translate(20.000000, 1565.000000)'%3E%3Cg id='Group-10' transform='translate(785.000000, 0.000000)'%3E%3Cg id='Group' transform='translate(30.000000, 327.000000)'%3E%3Cg id='bullet-point' transform='translate(0.000000, 110.973744)'%3E%3Cg id='ICONS/Action/Check' transform='translate(0.000000, 0.685467)'%3E%3Cpath d='M6.04240172,10.682289 L13.1761033,0.572678996 C13.5687251,-0.0343723841 14.3421686,-0.182461302 14.9032101,0.24258775 L14.9976109,0.318327853 C15.5586524,0.743376905 15.6948901,1.58103996 15.3022683,2.18809134 L7.51848797,15.1701735 C7.22455796,15.6234838 6.93277351,15.7817467 6.42000514,15.8190516 C5.90830955,15.855226 5.59936126,15.6494842 5.29148575,15.317132 L0.862154281,9.83445183 C0.371913492,9.30314055 0.371913492,8.44173803 0.862154281,7.91155723 L0.868590721,7.90816587 C1.35883158,7.37798507 2.15372978,7.37798507 2.64397065,7.90816587 L6.04240172,10.682289 Z' id='Icon-Color'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23A6A7AB' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: '';
        display: block;
        height: 0.75rem;
        margin-left: -1rem;
        position: absolute;
        transform: translateY(0.25rem);
        width: 0.75rem;

        @include media-from(sm) {
          height: 1rem;
          margin-left: -1.5rem;
          width: 1rem;
        }
      }
    }
  }
}

.packaging {
  margin: 0 auto;
  max-width: 200px;

  @include media-from(sm) {
    margin-right: 1rem;
  }

  &-text {
    display: flex;
    flex-direction: column;
    font-size: $font-size-18;
    justify-content: flex-start;
    margin: 0 2rem;

    @include media-from(sm) {
      margin: 0;
    }

    &-medium {
      font-weight: $font-weight-medium;
    }

    &-gray {
      color: $color-black--40;
    }
  }
}

.continue-button {
  margin-bottom: 0.5rem;
  width: 100%;
}

.continue-button-margin {
  @include media-from(sm) {
    margin: 0;
  }
}

.text-center {
  text-align: center;
}

.hr {
  border: 1px solid#f0f0f0;
  margin: 1.5rem 0 1.5rem;
  width: 100%;

  @include media-from(sm) {
    margin: 1.5rem 0 1.5rem -2rem;
    width: calc(100% + 4rem);
  }
}

.cloud-app-column-container {
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  & button {
    margin-top: 6px;
  }
}

.cloud-app-pictogram {
  @include media-from(sm) {
    & > div {
      height: 220px;
      width: 100%;
    }
  }

  @include media-to(sm) {
    & > div {
      height: 153px;
      width: 100%;
    }
  }
}

.onsite-survey-column-container {
  & p {
    font-size: $font-size-18;
    margin-top: 0;
  }

  & button {
    margin-top: 6px;
  }
}

.pictrogram {
  & span {
    display: block;
    height: 301px;
    position: relative;
    width: 100%;
  }

  @include media-to(lg) {
    & span {
      height: 225px;
    }
  }

  @media (min-width: map-get($breakpoints, 'xl')) and (max-height: 900px) {
    & span {
      height: 180px;
    }
  }

  svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.security-pictogram {
  position: relative;
}

.more-button-security {
  left: 6%;
  position: absolute;
  top: 5%;
  z-index: 10;

  @include media-to(lg) {
    left: 16%;
    top: 1%;
  }

  @include media-to(xs) {
    left: 2%;
    top: 7%;
  }

  @media (min-width: map-get($breakpoints, 'xl')) and (max-height: 900px) {
    left: 22%;
    top: -5%;
  }
}

.onsite-survey-image-container {
  margin: auto 0 auto auto;
  position: relative;
  text-align: center;

  @include media-to(md) {
    margin: auto;
  }

  @include media-to(xs) {
    width: auto;
  }

  & img {
    border-radius: 6px;
    height: 254px;
    object-fit: cover;
    object-position: top;
    width: 203px;

    @include media-to(sm) {
      height: 153px;
    }
  }

  .onsite-survey-badge {
    bottom: 28px;
    position: absolute;
    right: -62px;

    strong {
      font-weight: $font-weight-bold;
    }

    & > div {
      font-size: $font-size-16;

      @include media-to(sm) {
        font-size: $font-size-14;
      }
    }
  }
}
.icon-checkmark {
  color: $color-black--100;
  margin: auto 0.5rem;
}

.checkmark-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  span {
    margin: auto 0;
  }
}

.icon-edit {
  background-color: $color-white--100;
  color: $color-surrogate--100;
  margin: auto;
}

.icon-box {
  background-color: $color-white--100;
  border-radius: 15px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 8px 16px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 30px;
  margin-right: 0.5rem;
  width: 30px;
}

.edit-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  span {
    margin: auto 0;
  }
}

.plz-headline {
  color: $color-white--100;
  font-size: $font-size-24;
  font-weight: $font-weight-regular;
  line-height: 1.33;
  margin-bottom: -4rem;
  margin-top: 4rem;
  text-align: center;
}

.more-information-modal-button {
  color: $color-surrogate--100;
  cursor: pointer;
}

.hide-desktop {
  @include media-from(sm) {
    display: none;
  }
}

.bank-info {
  color: #94959a;
  font-size: $font-size-16;
  line-height: 1.5;
  margin-top: 0.75rem;

  @include media-from(sm) {
    margin-top: 1rem;
  }
}

.back-button-mobile {
  left: -1rem;
  position: absolute;
  top: 0.5rem;

  @include media-from(sm) {
    display: none;
  }

  &-no-header {
    top: 18px;
  }
}

.sepa-agreement {
  align-items: flex-start;
  display: flex;

  &-checkbox {
    margin-right: 1rem;
    margin-top: 0.5rem;
    min-height: 26px;
    min-width: 26px;
  }

  &-text {
    color: #94959a;
    font-size: $font-size-18;
    line-height: 1.33;

    &--errror {
      margin-top: -1.125rem;
    }
  }
}

.no-margin {
  margin: 0;
}

.summary-sub-label {
  color: #94959a;
  font-size: $font-size-16;
  margin-top: 1rem;
  max-width: 375px;
}

.customer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  @include media-from(sm) {
    flex-direction: row;
  }

  &__text {
    color: #94959a;
    font-size: $font-size-18;
    letter-spacing: 0;
    line-height: 1.4;
  }
}

.link {
  color: $color-surrogate--100;
  font-size: $font-size-14;
  line-height: 1.4;
}

.tool-tip {
  left: calc(100% + 0.75rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.tool-tip-mobile {
  margin-top: 16px;
  position: relative;
}

.customer-number {
  font-weight: $font-weight-medium;
}

.energy-info {
  margin-bottom: 2rem;

  span,
  ul {
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
  }
}

ul.energy-info-list {
  margin-bottom: 1rem;
  margin-top: 1rem;

  li {
    margin-bottom: 0.5rem;
  }
}

.onsite-survey-form-row {
  @include media-from(sm) {
    margin-left: auto;
    margin-right: auto;
    width: 470px;
  }
}

.thanks-additional-text {
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(640px + 64px);
  text-align: center;
}

.order-summary-item-align {
  display: flex;
  justify-content: space-between;
}

.discount-button {
  display: block;
  margin-top: 2.5rem;
}

.order-summary-payment-amount-highlight-amount-VAT {
  text-align: right;
}

.text-align-right {
  text-align: right;
}

.continue-box {
  margin: 0 auto;
  margin-bottom: 4rem;
  max-width: 640px;
  text-align: center;
}

.modal-button-margin {
  margin-top: 2.5rem;

  @include media-to(sm) {
    margin-top: 1.5rem;
  }
}

.payment-second-headline {
  font-size: $font-size-22;
  margin-bottom: 2rem;
  margin-top: 4rem;
  text-align: center;

  strong {
    font-weight: $font-weight-medium;
  }

  @include media-to(sm) {
    font-size: $font-size-20;
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }
}

.light-highlight {
  font-weight: $font-weight-medium;
}

.modal-header {
  font-size: $font-size-24;
  margin-bottom: 1.5rem;
  text-align: center;

  @include media-to(sm) {
    font-size: $font-size-22;
  }
}

.close-button {
  color: $color-surrogate--100;
  position: absolute;
  right: 0;
  top: 7px;

  @include media-from(sm) {
    display: none;
  }

  &-no-header {
    top: 19px;
  }
}
