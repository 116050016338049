@import '../../styles/index';

.headline {
  color: $color-red--100;
  font-size: $font-size-28;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  margin-bottom: 1.5rem !important;
  margin-top: 0;
  text-align: center;
}

.description {
  font-weight: $font-weight-light;
  font-size: $font-size-20;
  line-height: 1.4;
  margin: 0;
  text-align: center;
  margin-bottom: 1rem;
}

.hints {
  .hint {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.25rem;

    &-icon {
      color: $color-red--20;
      display: flex;
      justify-content: center;
    }

    &-text {
      font-weight: $font-weight-light;
      margin: 0;
    }
  }
}
