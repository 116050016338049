@import '../../styles/index';

.base {
  padding: 0.625rem 1.75rem;
  border-radius: 0.25rem;
}

.base,
.base p,
.base strong {
  color: $color-white--100;
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
  margin-block-start: 0;
  margin-block-end: 0;
}

.yellow {
  background: $color-yellow--100;
}

.green {
  background: $color-green--100;
}

.turquoise {
  background: $color-turquoise--100;
}

.blue {
  background: $color-blue--100;
}

.orange {
  background: $color-orange--100;
}
