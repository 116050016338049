@import '../../styles/index';

.base {
  align-items: center;
  border: 2px solid $color-surrogate--20;
  border-radius: 6px;
  color: $color-black--100;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 1.375rem 0;
  transition:
    color $duration-default,
    border $duration-default,
    background-color $duration-default;

  .icon {
    color: $color-surrogate--100;

    @include media-to(sm) {
      height: 1.75rem;
      width: 1.75rem;
    }
  }

  @include media-from(sm) {
    padding: 1.75rem 0;
  }

  &:hover {
    border-color: $color-surrogate--100;
    color: $color-surrogate--100;
  }

  &.clicked {
    background-color: $color-green--10;
    border-color: $color-green--100;
    color: $color-green--100;

    .icon {
      color: $color-green--100;
    }
  }
}

.icon {
  margin-bottom: 0.25rem;
}

.title {
  font-size: 1.125rem;
  line-height: 1.6;
}
