@import '../../styles/index';

.base {
  color: $color-surrogate--100;
  font-weight: $font-weight-medium;
  display: grid;
  font-size: $font-size-18;
  text-align: center;
  place-items: center;
  grid-template-rows: auto auto;
  gap: 1rem;
  padding: 1.5rem;
}

@include media-from(sm) {
  .base {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    justify-content: center;
    gap: 1.5rem;
    text-align: initial;
    font-size: $font-size-20;
  }
}

.left {
  width: 64px;
  height: 64px;
  contain: strict;
}

.right {
  max-width: 720px;
}

.icon {
  width: 100%;
  height: 100%;
}

.p {
  margin: 0;
}

.default {
  background: $color-surrogate--05;
}

.border {
  background-color: $color-white--100;
  border: 1px solid $color-surrogate--40;
}
